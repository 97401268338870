<template>
  <div class="my-9">
    <v-container v-for="(type, typeIndex) in documents" :key="`type-${typeIndex}`" class="py-0">
      <div v-for="(doc, index) in type" :key="`file-${index}`">
        <div v-if="type[index].file !== null" class="py-4">
          <div class="text-label float-left">{{ type[index].file.name }}</div>
          <div class="text-label float-right" v-if="type[index].uploadStatus">{{ type[index].uploadStatus }} %</div>
          <v-progress-linear :value="type[index].uploadStatus" :color="uiColor"></v-progress-linear>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DocumentUploadIndicator",
  components: {},
  data() {
    return {
      uiColor: this.$store.getters["registration/uiColor"],
    };
  },
  methods: {},
  computed: {
    documents: {
      get() {
        return this.$store.getters["registration/documents"];
      },
      set(value) {
        this.$store.dispatch("registration/updateDocuments", value);
      },
    },
  },
};
</script>
