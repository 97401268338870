<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <div class="mx-3 mt-3 mb-10">
      <!-- Employer -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <div class="text-label--title-big">{{ $t("registration.steps.3") }}</div>
          <span class="text-label">{{ $t("registration.professional_info.employer.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.professional_info.employer.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field
            v-model="professionalInfo.employer"
            :class="{ 'input-error': $v.professionalInfo.employer.$error }"
            solo
          ></v-text-field>
          <div class="text-error" v-if="$v.professionalInfo.employer.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Work Email -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.professional_info.work_email.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.professional_info.work_email.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field
            v-model="professionalInfo.workEmail"
            solo
            :class="{ 'input-error': $v.professionalInfo.workEmail.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.professionalInfo.employer.$error && !$v.professionalInfo.workEmail.required">
            {{ $t("registration.required_field") }}
          </div>
          <div class="text-error" v-if="$v.professionalInfo.workEmail.$error && !$v.professionalInfo.workEmail.email">
            {{ $t("registration.e-mail_invalid") }}
          </div>
        </v-col>
      </v-row>
      <!-- Job duties -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.professional_info.job_duties.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.professional_info.job_duties.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-textarea
            v-model="professionalInfo.jobDuties"
            solo
            :class="{ 'input-error': $v.professionalInfo.jobDuties.$error || jobDutiesWordCountError }"
          ></v-textarea>
          <div
            v-if="professionalInfo.jobDuties && !$v.professionalInfo.jobDuties.$error"
            :class="{ 'text-error': jobDutiesWordCountError, 'text-info': !jobDutiesWordCountError }"
          >
            <span v-if="professionalInfo.jobDuties.split(' ').length === 1"
              >{{ professionalInfo.jobDuties.split(" ").length }} word</span
            >
            <span v-if="professionalInfo.jobDuties.split(' ').length > 1"
              >{{ professionalInfo.jobDuties.split(" ").length }} words</span
            >
          </div>
          <div class="text-error" v-if="$v.professionalInfo.jobDuties.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- LinkedIN Profile -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.professional_info.linkedin.text") }}</span>
          <span class="text-optional">{{ $t("registration.optional") }}</span>
          <InfoTooltip :text="$t('registration.professional_info.linkedin.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field v-model="professionalInfo.linkedIn" solo></v-text-field>
        </v-col>
      </v-row>
      <!-- Job Title -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.professional_info.job_title.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-select
            :items="$t('registration.professional_info.job_title.answers')"
            attach
            solo
            class="select"
            :color="uiColor"
            v-model="professionalInfo.jobTitle"
            :item-color="uiColor"
            :class="{
              'input-error': $v.professionalInfo.jobTitle.$error,
            }"
          ></v-select>
          <div class="text-error" v-if="$v.professionalInfo.jobTitle.$error && !$v.professionalInfo.jobTitle.required">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Work Type -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.professional_info.work_type.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.professional_info.work_type.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="professionalInfo.workType" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.professional_info.work_type.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.professionalInfo.workType.$error,
              'input-error--text': $v.professionalInfo.workType.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.professionalInfo.workType.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div>
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import InfoTooltip from "./InfoTooltip";

export default {
  name: "ProfessionalInfo",
  components: {
    InfoTooltip,
  },
  data() {
    return {
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
    };
  },
  validations: {
    professionalInfo: {
      workEmail: {
        required,
        email,
      },
      employer: {
        required,
      },
      workType: {
        required,
      },
      jobDuties: {
        required,
      },
      jobTitle: {
        required,
      },
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error && !this.jobDutiesWordCountError) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    jobDutiesWordCountError() {
      if (!this.professionalInfo.jobDuties) {
        return false;
      }
      return this.professionalInfo.jobDuties.split(" ").length > 300;
    },
    professionalInfo: {
      get() {
        return this.$store.getters["registration/professionalInfo"];
      },
      set(value) {
        this.$store.dispatch("registration/updateProfessionalInfo", value);
      },
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
.radio-question--margin {
  margin-top: 0px !important;
  margin-right: 2rem;
}
.radio-block--margin {
  margin-bottom: -30px;
}
.radio-block--margin:last-of-type {
  margin-bottom: 0rem !important;
}
</style>
