<template>
  <v-app>
    <v-main>
      <router-view v-if="buildMode === 'default'"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      buildMode: process.env.VUE_APP_BUILD_MODE,
    };
  },
};
</script>
