<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <div class="mx-3 mt-3 mb-10">
      <!-- Brief description of roles and responsibilities at previous employer -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <div class="text-label--title-big mb-4">{{ $t("registration.steps.6") }}</div>
          <div>
            <span class="text-label">{{ $t("registration.key_experience.competence.text1") }}</span>
            <span class="text-optional">{{ $t("registration.required") }}</span>
          </div>
          <div class="my-4">
            <span class="text-label">{{ $t("registration.key_experience.competence.text2") }}</span>
            <a :href="$t('registration.key_experience.competence.url3')" target="_blank" class="text_link"
              ><span class="text-label">{{ $t("registration.key_experience.competence.text3") }}</span></a
            >
            <span class="text-label">{{ $t("registration.key_experience.competence.text4") }}</span>
          </div>

          <InfoTooltip :text="$t('registration.key_experience.competence.info')" />
        </v-col>
      </v-row>
      <!-- COMPETENCY 1 -->
      <v-row no-gutters align="center">
        <v-col cols="4" align="left">
          <div class="text-label">{{ $t("registration.key_experience.competence.competency1") }}</div>
        </v-col>
        <v-col cols="8" md="6" align="left">
          <v-select
            :items="competencies.filter((item) => item !== keyExperience.competency2.competency)"
            attach
            solo
            dense
            label="Competency"
            class="select"
            :color="uiColor"
            v-model="keyExperience.competency1.competency"
            :item-color="uiColor"
            :class="{
              'input-error': $v.keyExperience.competency1.competency.$error,
              focused: focused1,
            }"
            @focus="focused1 = true"
            @blur="focused1 = false"
          ></v-select>
          <div
            class="text-error"
            v-if="$v.keyExperience.competency1.competency.$error && !$v.keyExperience.competency1.competency.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" class="textfield-margin mt-3">
        <v-col cols="12" align="left">
          <v-textarea
            v-model="keyExperience.competency1.text"
            solo
            :class="{ 'input-error': $v.keyExperience.competency1.text.$error || competency1WordCountError }"
          ></v-textarea>
          <div
            v-if="keyExperience.competency1.text && !$v.keyExperience.competency1.text.$error"
            :class="{ 'text-error': competency1WordCountError, 'text-info': !competency1WordCountError }"
          >
            <span v-if="keyExperience.competency1.text.split(' ').length === 1"
              >{{ keyExperience.competency1.text.split(" ").length }} word</span
            >
            <span v-if="keyExperience.competency1.text.split(' ').length > 1"
              >{{ keyExperience.competency1.text.split(" ").length }} words</span
            >
          </div>
          <div
            class="text-error"
            v-if="$v.keyExperience.competency1.text.$error && !$v.keyExperience.competency1.text.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- COMPETENCY 2 -->
      <v-row no-gutters align="center">
        <v-col cols="4" align="left">
          <div class="text-label">{{ $t("registration.key_experience.competence.competency2") }}</div>
        </v-col>
        <v-col cols="8" md="6" align="left">
          <v-select
            :items="competencies.filter((item) => item !== keyExperience.competency1.competency)"
            attach
            solo
            dense
            label="Competency"
            class="select"
            :color="uiColor"
            v-model="keyExperience.competency2.competency"
            :item-color="uiColor"
            :class="{
              'input-error': $v.keyExperience.competency2.competency.$error,
              focused: focused2,
            }"
            @focus="focused2 = true"
            @blur="focused2 = false"
          ></v-select>
          <div
            class="text-error"
            v-if="$v.keyExperience.competency2.competency.$error && !$v.keyExperience.competency2.competency.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin mt-3">
        <v-col cols="12" align="left">
          <v-textarea
            v-model="keyExperience.competency2.text"
            solo
            :class="{ 'input-error': $v.keyExperience.competency2.text.$error || competency2WordCountError }"
          ></v-textarea>
          <div
            v-if="keyExperience.competency2.text && !$v.keyExperience.competency2.text.$error"
            :class="{ 'text-error': competency2WordCountError, 'text-info': !competency2WordCountError }"
          >
            <span v-if="keyExperience.competency2.text.split(' ').length === 1"
              >{{ keyExperience.competency2.text.split(" ").length }} word</span
            >
            <span v-if="keyExperience.competency2.text.split(' ').length > 1"
              >{{ keyExperience.competency2.text.split(" ").length }} words</span
            >
          </div>
          <div
            class="text-error"
            v-if="$v.keyExperience.competency2.text.$error && !$v.keyExperience.competency2.text.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div>
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InfoTooltip from "./InfoTooltip";

export default {
  name: "KeyExperience",
  components: {
    InfoTooltip,
  },
  data() {
    return {
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
      focused1: false,
      focused2: false,
    };
  },
  validations: {
    keyExperience: {
      competency1: {
        competency: {
          required,
        },
        text: {
          required,
        },
      },
      competency2: {
        competency: {
          required,
        },
        text: {
          required,
        },
      },
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error && !this.competency1WordCountError && !this.competency2WordCountError) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    competency1WordCountError() {
      if (!this.keyExperience.competency1.text) {
        return false;
      }
      return this.keyExperience.competency1.text.split(" ").length < 300;
    },
    competency2WordCountError() {
      if (!this.keyExperience.competency2.text) {
        return false;
      }
      return this.keyExperience.competency2.text.split(" ").length < 300;
    },
    competencies() {
      return this.$store.getters["registration/competencies"];
    },
    keyExperience: {
      get() {
        return this.$store.getters["registration/keyExperience"];
      },
      set(value) {
        this.$store.dispatch("registration/updateKeyExperience", value);
      },
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
.radio-question--margin {
  margin-top: 0px !important;
  margin-right: 2rem;
}
.radio-block--margin {
  margin-bottom: -30px;
}
.radio-block--margin:last-of-type {
  margin-bottom: 0rem !important;
}

.select.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.select {
  margin-bottom: -18px;
}

.text_link {
  color: rgba(0, 0, 0, 0.7) !important;
}
.text_link:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text_link .text-label:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
</style>
