<template>
  <v-card class="main-thread" tile elevation="2" id="main-thread" :style="color">
    <v-row no-gutters class="main-container">
      <v-col cols="4" class="steps-sidebar d-none d-md-flex flex-column">
        <div class="d-flex steps-container flex-column justify-center">
          <template v-for="stepData in stepsToShow">
            <div
              class="d-flex align-center mb-3"
              v-bind:class="step < stepNumber(stepData.name) ? 'step-element' : 'cursor--pointer'"
              v-on:click="stepBackwards(stepNumber(stepData.name))"
              :key="stepData.name"
              v-if="stepData.condition"
            >
              <v-avatar color="#FFFFFF" size="32" class="mr-3">
                <span class="steps-green-text">{{ stepNumber(stepData.name) }}</span>
              </v-avatar>
              <span class="steps-white-text">{{ stepData.title }}</span>
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="12" md="8" class="wrapper">
        <!-- STEP -->
        <template v-for="stepData in stepsToShow">
          <div
            class="step--mobile d-flex d-md-none"
            v-bind:class="step < stepNumber(stepData.name) ? 'step-element' : ''"
            v-on:click="stepBackwards(stepNumber(stepData.name))"
            :key="stepData.name"
            v-if="stepData.condition"
          >
            <v-avatar color="#FFFFFF" size="32" class="mr-3">
              <span class="steps-green-text">{{ stepNumber(stepData.name) }}</span>
            </v-avatar>
            <span class="steps-white-text">{{ stepData.title }}</span>
          </div>
          <transition name="fade" mode="out-in" :key="stepData.name">
            <div
              class="content--mobile"
              v-if="stepData.condition && step === stepNumber(stepData.name)"
              transition="fade"
            >
              <div v-if="trainerSettings && trainerSettings.logo" class="trainer-logo--container">
                <div class="trainer-logo--text">{{ $t("registration.trainer_logo_prefix") }}</div>
                <img class="trainer-logo--img" :src="trainerSettings.logo" />
              </div>
              <component
                :class="{ 'main-component': trainerSettings && trainerSettings.logo }"
                v-bind:is="stepData.name"
                v-on:next-button-clicked="onNextButtonClicked()"
                v-on:back-button-clicked="onBackButtonClicked()"
              ></component>
            </div>
          </transition>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PersonalInfo from "./PersonalInfo";
import Summary from "./Summary";
import ProfessionalInfo from "./ProfessionalInfo";
//import ProfessionalReferences from "./ProfessionalReferences";
import KeyExperience from "./KeyExperience";
import Demographics from "./Demographics";
import CertificationType from "./CertificationType";
import Eligibility from "./Eligibility";

export default {
  name: "Registration",
  components: {
    PersonalInfo,
    Summary,
    ProfessionalInfo,
    //ProfessionalReferences,
    KeyExperience,
    Demographics,
    CertificationType,
    Eligibility,
  },
  data() {
    return {
      step: 1,
      buildMode: process.env.VUE_APP_BUILD_MODE,
    };
  },
  methods: {
    setStep(number) {
      this.step = number;
    },
    onNextButtonClicked() {
      this.step++;
      this.scrollToTop();
    },
    onBackButtonClicked() {
      this.step--;
      this.scrollToTop();
    },
    scrollToTop() {
      if (typeof window !== "undefined") {
        window.goToTop(this.buildMode);
      }
    },
    stepBackwards(clickedOn) {
      if (!this.registrationCompleted) {
        if (clickedOn < this.step) {
          this.step = clickedOn;
          this.scrollToTop();
        }
      }
    },
    stepNumber(stepName) {
      var i = 0;
      for (var step of this.stepsToShow) {
        if (step.condition) {
          i++;
        }
        if (step.name === stepName) {
          return i;
        }
      }
    },
  },
  computed: {
    trainerSettings() {
      return this.$store.state.registration.trainerSettings;
    },
    registrationCompleted() {
      return this.$store.getters["registration/registrationCompleted"];
    },
    locale() {
      return this.$store.getters["registration/locale"];
    },
    stepsToShow() {
      return [
        {
          name: "CertificationType",
          condition: true,
          title: this.$t("registration.steps.1"),
        },
        {
          name: "PersonalInfo",
          condition: true,
          title: this.$t("registration.steps.2"),
        },
        {
          name: "ProfessionalInfo",
          condition: true,
          title: this.$t("registration.steps.3"),
        },
        /*{
          name: "ProfessionalReferences",
          condition: true,
          title: this.$t("registration.steps.4"),
        },*/
        {
          name: "Eligibility",
          condition: true,
          title: this.$t("registration.steps.5"),
        },
        {
          name: "KeyExperience",
          condition: true,
          title: this.$t("registration.steps.6"),
        },
        {
          name: "Demographics",
          condition: true,
          title: this.$t("registration.steps.7"),
        },
        {
          name: "Summary",
          condition: true,
          title: this.$t("registration.steps.8"),
        },
      ];
    },
    color() {
      return {
        "--var-ui-color": this.$store.getters["registration/uiColor"],
      };
    },
  },
};
</script>

<style scoped>
.main-thread {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  min-height: 500px;
  display: flex;
  margin-bottom: 42px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.padding-summary-mobile {
  padding-left: 12px;
}
.steps-sidebar {
  background-color: var(--var-ui-color);
}
.steps-container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 80px;
  margin-bottom: 80px;
}
.steps-green-text {
  color: var(--var-ui-color);
  font-weight: 800;
  font-size: 14pt;
}
.steps-white-text {
  color: white;
  font-weight: 500;
  font-size: 12pt;
}
.step-element {
  opacity: 0.3;
}
.step-element:active {
  opacity: 1;
}
.wrapper {
  position: relative;
}

.step--mobile {
  background-color: var(--var-ui-color);
  margin-left: 0;
  margin-right: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.step--mobile-summary {
  background-color: var(--var-ui-color);
  margin-left: 0;
  margin-right: 0;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
@media (max-width: 960px) {
  .content--mobile {
    height: fit-content !important;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  .main-thread {
    min-height: fit-content;
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s;
    overflow: hidden;
  }
  .fade-enter,
  .fade-leave-to {
    height: 0;
    opacity: 0;
  }
  .trainer-logo--img {
    max-width: 150px !important;
    max-height: 75px !important;
  }
  .main-component {
    padding-top: 5.5rem;
  }
}
@media (min-width: 961px) {
  .main-component {
    padding-top: 3.5rem;
  }
  .trainer-logo--img {
    max-width: 200px;
    max-height: 100px;
  }
}
.trainer-logo--text {
  font-size: 0.85rem;
  padding-right: 0rem;
  margin-bottom: 0.3rem;
}
.trainer-logo--container {
  position: absolute;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  flex-direction: column !important;
  align-items: flex-end !important;
  padding: 0.8rem;
}
.content--mobile {
  height: 100%;
}
.vertical-center {
  vertical-align: middle;
}
.summary--mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: fit-content;
  background-color: #a78682;
  width: 100%;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
}
.summary--desktop {
  padding: 12px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: fit-content;
  background-color: #a78682;
  width: 100%;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
}
.summary-margin {
  margin-top: -20px;
}
.summary-margin--logos {
  margin-top: -30px;
}
.cursor--pointer {
  cursor: pointer;
}
.margin-left-big {
  margin-left: 36px;
}
</style>
