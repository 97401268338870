export async function getInitialApplication(id) {
  for (var i = 1; i <= 5; i++) {
    try {
      const res = await fetch(process.env.VUE_APP_BACKEND_URL + "/initial-application/" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      if (!res.ok) {
        console.log("request failed");
        if (i === 5) {
          return {};
        }
      } else {
        return res.json();
      }
    } catch (err) {
      console.log(err);
      if (i === 5) {
        return {};
      }
    }
  }
}
