var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"main-container d-flex flex-column justify-center"},[_c('div',{staticClass:"mx-3 mt-3 mb-10"},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('div',{staticClass:"text-label--title-big mb-4"},[_vm._v(_vm._s(_vm.$t("registration.steps.5")))]),_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.eligibility.highest_level.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.eligibility.highest_level.info')}})],1)],1),_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.eligibility.highestLevel),callback:function ($$v) {_vm.$set(_vm.eligibility, "highestLevel", $$v)},expression:"eligibility.highestLevel"}},_vm._l((_vm.$t('registration.eligibility.highest_level.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.eligibility.highestLevel.$error,
            'input-error--text': _vm.$v.eligibility.highestLevel.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.eligibility.highestLevel.$error && !_vm.$v.eligibility.highestLevel.required)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1),(_vm.certId === 'CMPCL')?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.eligibility.years_leader_customer_contact.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.eligibility.years_leader_customer_contact.info')}})],1)],1):_vm._e(),(_vm.certId === 'CMPCL')?_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.eligibility.yearsLeaderCustomerContact),callback:function ($$v) {_vm.$set(_vm.eligibility, "yearsLeaderCustomerContact", $$v)},expression:"eligibility.yearsLeaderCustomerContact"}},_vm._l((_vm.$t('registration.eligibility.years_leader_customer_contact.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.eligibility.yearsLeaderCustomerContact.$error,
            'input-error--text': _vm.$v.eligibility.yearsLeaderCustomerContact.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.eligibility.yearsLeaderCustomerContact.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1):_vm._e(),(_vm.certId === 'CMPCL')?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.eligibility.years_leader_management.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.eligibility.years_leader_management.info')}})],1)],1):_vm._e(),(_vm.certId === 'CMPCL')?_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.eligibility.yearsLeaderManagement),callback:function ($$v) {_vm.$set(_vm.eligibility, "yearsLeaderManagement", $$v)},expression:"eligibility.yearsLeaderManagement"}},_vm._l((_vm.$t('registration.eligibility.years_leader_management.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.eligibility.yearsLeaderManagement.$error,
            'input-error--text': _vm.$v.eligibility.yearsLeaderManagement.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.eligibility.yearsLeaderManagement.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1):_vm._e(),(_vm.certId === 'CMPCA')?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.eligibility.years_agent.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.eligibility.years_agent.info')}})],1)],1):_vm._e(),(_vm.certId === 'CMPCA')?_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.eligibility.yearsAgent),callback:function ($$v) {_vm.$set(_vm.eligibility, "yearsAgent", $$v)},expression:"eligibility.yearsAgent"}},_vm._l((_vm.$t('registration.eligibility.years_agent.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.eligibility.yearsAgent.$error,
            'input-error--text': _vm.$v.eligibility.yearsAgent.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.eligibility.yearsAgent.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"next-button",attrs:{"tile":"","color":_vm.uiColor},on:{"click":_vm.validateForm}},[_vm._v(_vm._s(_vm.$t("registration.proceed")))]),_c('v-btn',{staticClass:"back-button",attrs:{"tile":"","color":_vm.uiColor},on:{"click":function($event){return _vm.$emit('back-button-clicked')}}},[_vm._v(_vm._s(_vm.$t("registration.back")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }