<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <div class="mx-3 mt-3 mb-10">
      <!-- Annual Reviews -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <div class="text-label--title-big mb-4">{{ $t("registration.steps.5") }}</div>
          <span class="text-label">{{ $t("registration.eligibility.highest_level.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.eligibility.highest_level.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="eligibility.highestLevel" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.eligibility.highest_level.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.eligibility.highestLevel.$error,
              'input-error--text': $v.eligibility.highestLevel.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.eligibility.highestLevel.$error && !$v.eligibility.highestLevel.required">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Years of Experience - Leader - Customer Contact-->
      <v-row no-gutters align="center" class="mb-2" v-if="certId === 'CMPCL'">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.eligibility.years_leader_customer_contact.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.eligibility.years_leader_customer_contact.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin" v-if="certId === 'CMPCL'">
        <v-radio-group v-model="eligibility.yearsLeaderCustomerContact" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.eligibility.years_leader_customer_contact.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.eligibility.yearsLeaderCustomerContact.$error,
              'input-error--text': $v.eligibility.yearsLeaderCustomerContact.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.eligibility.yearsLeaderCustomerContact.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Years of Experience - Leader - Management -->
      <v-row no-gutters align="center" class="mb-2" v-if="certId === 'CMPCL'">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.eligibility.years_leader_management.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.eligibility.years_leader_management.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin" v-if="certId === 'CMPCL'">
        <v-radio-group v-model="eligibility.yearsLeaderManagement" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.eligibility.years_leader_management.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.eligibility.yearsLeaderManagement.$error,
              'input-error--text': $v.eligibility.yearsLeaderManagement.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.eligibility.yearsLeaderManagement.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Years of Experience - Agent -->
      <v-row no-gutters align="center" class="mb-2" v-if="certId === 'CMPCA'">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.eligibility.years_agent.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.eligibility.years_agent.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin" v-if="certId === 'CMPCA'">
        <v-radio-group v-model="eligibility.yearsAgent" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.eligibility.years_agent.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.eligibility.yearsAgent.$error,
              'input-error--text': $v.eligibility.yearsAgent.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.eligibility.yearsAgent.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div>
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import InfoTooltip from "./InfoTooltip";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Eligibility",
  components: {
    InfoTooltip,
  },
  data() {
    return {
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
    };
  },
  validations: {
    eligibility: {
      highestLevel: {
        required,
      },
      yearsLeaderCustomerContact: {
        required: requiredIf(function () {
          return this.certId === "CMPCL";
        }),
      },
      yearsLeaderManagement: {
        required: requiredIf(function () {
          return this.certId === "CMPCL";
        }),
      },
      yearsAgent: {
        required: requiredIf(function () {
          return this.certId === "CMPCA";
        }),
      },
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    eligibility: {
      get() {
        return this.$store.getters["registration/eligibility"];
      },
      set(value) {
        this.$store.dispatch("registration/eligibility", value);
      },
    },
    certId() {
      return this.$store.getters["registration/certificationType"].certId;
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
.radio-question--margin {
  margin-top: 0px !important;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.radio-block--margin {
  margin-bottom: -30px;
}
.radio-block--margin:last-of-type {
  margin-bottom: 0rem !important;
}
</style>
