<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <!-- Checkout texts -->
    <RegInfoSummary v-if="!paymentSuccessful" />

    <!-- REGISTRATION SUCCESS -->
    <div v-if="paymentSuccessful" class="text-center">
      <v-icon color="#625F4E" size="100"> $vuetify.icons.checkboxMarkedCircle </v-icon>
      <div class="text-checkout-sum mt-3">
        {{ $t("registration.payment_successful_1") }}
      </div>
      <div class="text-checkout-details mt-2">
        {{ $t("registration.payment_successful_2") }}
      </div>
      <div class="text-checkout-details mt-2" v-if="documentUploadSuccess">
        {{ $t("registration.upload_success") }}
      </div>
    </div>

    <!-- REGISTRATION FAILED -->
    <v-row v-if="paymentFailed && !paymentSuccessful">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="pt-4 textfield-margin pb-5">
        <v-alert dense color="#f0404f" outlined>
          <v-icon left color="#f0404f"> $vuetify.icons.closeCircle </v-icon
          ><span>{{ $t("registration.registration_failed") }} ({{ errorCode }})</span></v-alert
        >
      </v-col>
    </v-row>

    <!-- DOCUMENT UPLOAD FAILED -->
    <v-row v-if="documentUploadError && !documentUploadSuccess">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="pt-4 textfield-margin pb-5">
        <v-alert dense color="#f0404f" outlined>
          <v-icon left color="#f0404f"> $vuetify.icons.closeCircle </v-icon
          ><span>{{ $t("registration.upload_error") }}</span></v-alert
        >
      </v-col>
    </v-row>

    <!-- TO ACCEPT -->
    <template v-if="!paymentSuccessful">
      <div>
        <v-row v-for="consent in consentDeclarations" :key="consent.id" class="checkbox-margin">
          <v-col cols="12" md="10" offset="0" offset-md="1" align="left">
            <v-checkbox
              v-model="consentAccepted[consent.id]"
              :color="uiColor"
              :class="{
                'input-error': invalidConsentFields.includes(consent.id),
              }"
            >
              <span
                slot="label"
                class="text-label"
                :class="{
                  'input-error--text': invalidConsentFields.includes(consent.id),
                }"
              >
                <component :is="convertLink(consent.text, consent.dynamic_link)"></component>
              </span>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
    </template>

    <!-- UPLOAD INDICATOR -->
    <v-row v-if="lockSubmit && !documentsEmpty">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="payment-margin mb-5">
        <DocumentUploadIndicator />
      </v-col>
    </v-row>

    <!-- REGISTER WITHOUT PAYING -->
    <v-row v-if="!paymentSuccessful">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="payment-margin mb-5">
        <div class="text-center">
          <v-btn
            tile
            large
            :color="uiColor"
            v-on:click="requestUserSecret()"
            :disabled="lockSubmit"
            v-if="!lockSubmit && documentsEmpty"
            >{{ $t("registration.register") }}</v-btn
          >
          <v-btn
            tile
            large
            :color="uiColor"
            v-on:click="requestUserSecret()"
            :disabled="lockSubmit"
            v-if="!lockSubmit && !documentsEmpty"
            >{{ $t("registration.register_and_upload") }}</v-btn
          >
          <v-progress-circular
            :size="50"
            :width="7"
            :color="uiColor"
            indeterminate
            v-if="lockSubmit"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>

    <!-- Navigation Buttons -->
    <div class="pt-3" v-if="!paymentSuccessful">
      <v-btn
        tile
        :color="uiColor"
        :disabled="lockSubmit"
        class="back-button"
        v-on:click="$emit('back-button-clicked')"
        >{{ $t("registration.back") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { getUserSecret } from "../services/CheckoutService";
import { uploadDocuments } from "../services/DocumentUploadService";
import RegInfoSummary from "./RegInfoSummary";
import DocumentUploadIndicator from "./DocumentUploadIndicator";

export default {
  name: "Summary",
  components: {
    RegInfoSummary,
    DocumentUploadIndicator,
  },
  data() {
    return {
      lockSubmit: false,
      onlyVoucher: false,
      nextClicked: false,
      paymentSuccessful: false,
      paymentFailed: false,
      tenant: this.$store.getters["registration/tenant"],
      invalidConsentFields: [],
      documentsEmpty: this.$store.getters["registration/documentsEmpty"],
      documents: this.$store.getters["registration/documents"],
      random: this.$store.getters["registration/registrationRandom"],
      uiColor: this.$store.getters["registration/uiColor"],
      errorCode: null,
    };
  },
  methods: {
    convertLink(text, dynamic_link) {
      //check for links [text](url)
      let elements = text.match(/\[.*?\)/g);
      if (elements != null && elements.length > 0) {
        var index = 0;
        for (var el of elements) {
          let txt = el.match(/\[(.*?)\]/)[1]; //get only the txt
          let url = el.match(/\((.*?)\)/)[1]; //get only the link
          if (dynamic_link) {
            if (dynamic_link[index]) {
              url = "https://www.certible.com"; //TODO
            }
          }

          text = text.replace(
            el,
            '<a href="' +
              url +
              '" @click.stop target="_blank" rel="noopener noreferrer" class="text-link--lib">' +
              txt +
              "</a>"
          );
          index++;
        }
      }
      text = "<span>" + text + "</span>";
      return {
        template: text,
      };
    },
    validateForm() {
      this.nextClicked = true;
      if (this.validateData()) {
        this.$emit("next-button-clicked");
      }
    },
    getDataToSend() {
      var regInfo = this.$store.getters["registration/registrationData"];
      return regInfo;
    },
    validateConsentFields() {
      this.invalidConsentFields = [];
      for (var consent of this.consentDeclarations) {
        if (consent.mandatory === true) {
          if (!(this.consentAccepted[consent.id] && this.consentAccepted[consent.id] === true)) {
            this.invalidConsentFields.push(consent.id);
          }
        }
      }
      if (this.invalidConsentFields.length > 0) {
        return false;
      }
      return true;
    },
    validateData() {
      var validatedConsentFields = this.validateConsentFields();
      if (validatedConsentFields) {
        return true;
      }
      return false;
    },
    async requestUserSecret() {
      if (this.validateData()) {
        this.lockSubmit = true;
        if (!this.documentsEmpty) {
          this.uploadResult = await uploadDocuments(this.documents, this.tenant, this.random);
          if (Object.keys(this.uploadResult.failed).length === 0) {
            this.documentUploadSuccess = true;
          } else {
            this.documentUploadError = true;
          }
        }
        const dataToSend = this.getDataToSend();
        getUserSecret(dataToSend).then((res) => {
          if (res && !res.error) {
            console.log("user secret successfully obtained");
            this.lockSubmit = false;
            this.paymentSuccessful = true;
            this.registrationCompleted = true;
          } else {
            console.log("user secret failed");
            this.lockSubmit = false;
            this.paymentFailed = true;
            if (res.error && res.status) {
              this.errorCode = res.status;
            }
          }
        });
      }
    },
  },
  computed: {
    consentDeclarations() {
      return this.$store.getters["registration/consentDeclarations"];
    },
    locale() {
      return this.$store.getters["registration/locale"];
    },
    consentAccepted: {
      get() {
        return this.$store.getters["registration/consentAccepted"];
      },
      set(value) {
        this.$store.dispatch("registration/updateConsentAccepted", value);
      },
    },
    registrationCompleted: {
      get() {
        return this.$store.getters["registration/registrationCompleted"];
      },
      set(value) {
        this.$store.dispatch("registration/updateRegistrationCompleted", value);
      },
    },
    documentUploadSuccess: {
      get() {
        return this.$store.getters["registration/documentUploadSuccess"];
      },
      set(value) {
        this.$store.dispatch("registration/updateDocumentUploadSuccess", value);
      },
    },
    documentUploadError: {
      get() {
        return this.$store.getters["registration/documentUploadError"];
      },
      set(value) {
        this.$store.dispatch("registration/updateDocumentUploadError", value);
      },
    },
    uploadResult: {
      get() {
        return this.$store.getters["registration/uploadResult"];
      },
      set(value) {
        this.$store.dispatch("registration/updateUploadResult", value);
      },
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
@media (max-width: 960px) {
  .main-container {
    height: fit-content !important;
  }
  .checkbox-margin {
    margin-top: -40px !important;
  }
  .checkbox-margin--bottom {
    margin-bottom: -40px !important;
  }
}
.payment-margin {
  margin-top: -10px;
}
.checkbox-margin {
  margin-bottom: -60px;
}
.checkbox-margin:last-of-type {
  margin-bottom: 20px;
}
.input-error ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.black--text ::v-deep label {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.paypal-mobile {
  margin-left: -17px;
}
</style>
