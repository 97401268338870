<template>
  <div>
    <!-- CMP Header -->
    <div id="header_main">
      <div id="header_main_inner" class="container_cmp">
        <div id="header_main_alpha" role="banner">
          <div id="header_logo">
            <a :href="$t('registration.header_url')">
              <img id="hlogo_normal" src="@/assets/cmp_logo.png" alt="Logo - Customer Management Practice" />
              <img id="hlogo_icon" src="@/assets/cmp_logo.png" alt="Logo Icon - Customer Management Practice" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <Registration />
  </div>
</template>

<script>
import Registration from "./Registration";

export default {
  name: "RegistrationStart",
  components: {
    Registration,
  },
  beforeCreate() {
    let urlParams = new URLSearchParams(window.location.search);
    this.$store.dispatch("registration/loadInitialApplication", urlParams.get("id"));
    if (urlParams.has("trainer")) {
      this.$store.dispatch("registration/loadTrainerSettings", urlParams.get("trainer"));
    }
  },
};
</script>

<style>
#header_main {
  height: 120px;
  box-sizing: border-box;
  padding-top: 20px;
  border-bottom: 1px solid #d9dadb;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  z-index: 10;
  position: relative;
  margin-bottom: 56px;
}
#header_main_inner {
  height: 100%;
  display: flex;
  max-width: 1280px;
}
#header_main_alpha {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.container_cmp {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
#header_logo {
  cursor: pointer;
}
@media (max-width: 960px) {
  #hlogo_normal {
    display: none;
  }
  #header_logo img {
    max-height: 150px;
    max-width: 200px;
  }
  #header_main {
    height: 64px;
    padding: 0;
  }
}
@media (min-width: 960px) {
  #hlogo_icon {
    display: none;
  }
}
#header_logo img {
  width: 250px;
  height: auto;
  vertical-align: middle;
  border-style: none;
}
</style>
