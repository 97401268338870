var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"main-container d-flex flex-column justify-center"},[_c('div',{staticClass:"mx-3 mt-3 mb-10"},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('div',{staticClass:"text-label--title-big mb-4"},[_vm._v(_vm._s(_vm.$t("registration.steps.7")))]),_c('div',{staticClass:"text-label"},[_vm._v(" "+_vm._s(_vm.$t("registration.demographics.info"))+" ")]),_c('v-divider',{staticClass:"mt-3 mb-3"}),_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.business_model.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.business_model.info')}})],1)],1),_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.demographics.businessModel),callback:function ($$v) {_vm.$set(_vm.demographics, "businessModel", $$v)},expression:"demographics.businessModel"}},_vm._l((_vm.$t('registration.demographics.business_model.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.demographics.businessModel.$error,
            'input-error--text': _vm.$v.demographics.businessModel.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.demographics.businessModel.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.annual_reviews.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.annual_reviews.info')}})],1)],1),_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.demographics.annualReviews),callback:function ($$v) {_vm.$set(_vm.demographics, "annualReviews", $$v)},expression:"demographics.annualReviews"}},_vm._l((_vm.$t('registration.demographics.annual_reviews.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.demographics.annualReviews.$error,
            'input-error--text': _vm.$v.demographics.annualReviews.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.demographics.annualReviews.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.customer_satisfaction.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.customer_satisfaction.info')}})],1)],1),_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.demographics.customerSatisfaction),callback:function ($$v) {_vm.$set(_vm.demographics, "customerSatisfaction", $$v)},expression:"demographics.customerSatisfaction"}},_vm._l((_vm.$t('registration.demographics.customer_satisfaction.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.demographics.customerSatisfaction.$error,
            'input-error--text': _vm.$v.demographics.customerSatisfaction.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.demographics.customerSatisfaction.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.stay_at_current_employer.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.stay_at_current_employer.info')}})],1)],1),_c('v-row',{staticClass:"textfield-margin",attrs:{"no-gutters":"","align":"center"}},[_c('v-radio-group',{staticClass:"radio-question--margin",model:{value:(_vm.demographics.stayAtCurrentEmployer),callback:function ($$v) {_vm.$set(_vm.demographics, "stayAtCurrentEmployer", $$v)},expression:"demographics.stayAtCurrentEmployer"}},_vm._l((_vm.$t('registration.demographics.stay_at_current_employer.answers')),function(answer){return _c('v-radio',{key:answer,staticClass:"black--text radio-button--margin",class:{
            'black--text': !_vm.$v.demographics.stayAtCurrentEmployer.$error,
            'input-error--text': _vm.$v.demographics.stayAtCurrentEmployer.$error,
          },attrs:{"label":answer,"color":_vm.uiColor,"value":answer}})}),1),(_vm.$v.demographics.stayAtCurrentEmployer.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1),(_vm.certId === 'CMPCA')?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.channels_agent.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.channels_agent.info')}})],1)],1):_vm._e(),(_vm.certId === 'CMPCA')?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('v-select',{staticClass:"select",class:{
            'input-error': _vm.$v.demographics.channelsAgent.$error,
            focused: _vm.focused1,
          },attrs:{"items":_vm.$t('registration.demographics.channels_agent.answers'),"multiple":"","attach":"","solo":"","label":"Channels","color":_vm.uiColor,"item-color":_vm.uiColor,"menu-props":{ top: true }},on:{"focus":function($event){_vm.focused1 = true},"blur":function($event){_vm.focused1 = false}},model:{value:(_vm.demographics.channelsAgent),callback:function ($$v) {_vm.$set(_vm.demographics, "channelsAgent", $$v)},expression:"demographics.channelsAgent"}}),(_vm.$v.demographics.channelsAgent.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.certId === 'CMPCL')?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("registration.demographics.channels_leader.text")))]),_c('span',{staticClass:"text-optional"},[_vm._v(_vm._s(_vm.$t("registration.required")))]),_c('InfoTooltip',{attrs:{"text":_vm.$t('registration.demographics.channels_leader.info')}})],1)],1):_vm._e(),(_vm.certId === 'CMPCL')?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('v-select',{staticClass:"select",class:{
            'input-error': _vm.$v.demographics.channelsLeader.$error,
            focused: _vm.focused2,
          },attrs:{"items":_vm.$t('registration.demographics.channels_leader.answers'),"multiple":"","attach":"","solo":"","label":"Channels","color":_vm.uiColor,"item-color":_vm.uiColor,"menu-props":{ top: true }},on:{"focus":function($event){_vm.focused2 = true},"blur":function($event){_vm.focused2 = false}},model:{value:(_vm.demographics.channelsLeader),callback:function ($$v) {_vm.$set(_vm.demographics, "channelsLeader", $$v)},expression:"demographics.channelsLeader"}}),(_vm.$v.demographics.channelsLeader.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.$t("registration.required_field"))+" ")]):_vm._e()],1)],1):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"next-button",attrs:{"tile":"","color":_vm.uiColor},on:{"click":_vm.validateForm}},[_vm._v(_vm._s(_vm.$t("registration.proceed")))]),_c('v-btn',{staticClass:"back-button",attrs:{"tile":"","color":_vm.uiColor},on:{"click":function($event){return _vm.$emit('back-button-clicked')}}},[_vm._v(_vm._s(_vm.$t("registration.back")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }