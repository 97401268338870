<template>
  <div>
    <!-- CMP Header -->
    <div id="header_main">
      <div id="header_main_inner" class="container_cmp">
        <div id="header_main_alpha" role="banner">
          <div id="header_logo">
            <a :href="$t('registration.header_url')">
              <img id="hlogo_normal" src="@/assets/cmp_logo.png" alt="Logo - Customer Management Practice" />
              <img id="hlogo_icon" src="@/assets/cmp_logo.png" alt="Logo Icon - Customer Management Practice" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="badges-container">
      <div v-if="!loading && badge && badge.id">
        <v-row>
          <v-col cols="12" sm="6" md="8" order="2" order-sm="1">
            <div class="d-none d-sm-block">
              <div class="badge-title">{{ personalData.name }}</div>
              <div class="badge-subtitle">{{ badgeClass.name }}</div>
              <div class="badge-issued-text">{{ $i18n.t("badges.issued") }} {{ badge.issuedOn.split("T")[0] }}</div>
              <div class="badge-issued-text" v-if="badge.expires">
                {{ $i18n.t("badges.expires") }} {{ badge.expires.split("T")[0] }}
              </div>
              <v-divider class="badge-text--divider" />
            </div>
            <div class="badge-text">{{ badgeClass.description }}</div>
            <!-- CRITERIA -->
            <div class="badge-text" v-if="badgeClass.criteria.id && badgeClass.criteria.narrative">
              <div class="badge-text-title">{{ $i18n.t("badges.earning_criteria") }}</div>
              <div v-html="getNarrative()"></div>
              <div>
                <a target="_blank" :href="badgeClass.criteria.id">{{ $i18n.t("badges.criteria_url_name") }}</a>
              </div>
            </div>
            <!-- ALIGNMENT -->
            <div class="badge-text" v-if="badgeClass.alignment.length > 0">
              <div class="badge-text-title">{{ $i18n.t("badges.alignment_targets") }}</div>
              <div v-for="target in badgeClass.alignment" :key="target.targetUrl" class="badge-target-name">
                <a target="_blank" :href="target.targetUrl">{{ target.targetName }}</a>
                <div class="badge-text--line-break">{{ target.targetDescription }}</div>
              </div>
            </div>
            <!-- TAGS -->
            <div class="badge-chips-container">
              <div class="badge-text-title">{{ $i18n.t("badges.tags") }}</div>
              <v-chip class="badge-chips" label v-for="tag in badgeClass.tags" :key="tag">{{ tag }}</v-chip>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            offset-sm="0"
            offset-md="0"
            order="1"
            order-sm="2"
            align="center"
            sm-align="right"
          >
            <div class="d-block d-sm-none">
              <div class="badge-title">{{ personalData.name }}</div>
              <div class="badge-subtitle">{{ badgeClass.name }}</div>
              <div class="badge-issued-text">{{ $i18n.t("badges.issued") }} {{ badge.issuedOn.split("T")[0] }}</div>
              <div class="badge-issued-text" v-if="badge.expires">
                {{ $i18n.t("badges.expires") }} {{ badge.expires.split("T")[0] }}
              </div>
              <v-divider class="badge-text--divider" />
            </div>
            <!-- LOGO -->
            <div class="badge--share-container">
              <div class="badge-image-container">
                <img
                  class="badge-image"
                  :src="`${badgesAssetsAPI}/baked-badges/cmp/${id}.${badgeClass.image.substring(
                    badgeClass.image.lastIndexOf('.') + 1
                  )}`"
                />
              </div>
              <!-- DOWNLOAD BUTTON -->
              <a
                :download="`${badgesAssetsAPI}/baked-badges/cmp/${id}.${badgeClass.image.substring(
                  badgeClass.image.lastIndexOf('.') + 1
                )}`"
                :href="`${badgesAssetsAPI}/baked-badges/cmp/${id}.${badgeClass.image.substring(
                  badgeClass.image.lastIndexOf('.') + 1
                )}`"
                class="submit-button-container"
              >
                <v-btn elevation="2" class="text-none submit-button" color="#6c6c6c" tile @click="true">
                  <v-icon>$vuetify.icons.cloudDownload</v-icon>
                  &nbsp;
                  <div class="text-none share-button--text">{{ $i18n.t("badges.download") }}</div></v-btn
                >
              </a>
              <!-- COPY BADGE LINK -->

              <v-expansion-panels tile>
                <v-expansion-panel>
                  <v-expansion-panel-header color="#6c6c6c" class="copy-panel--header">
                    <v-icon>$vuetify.icons.codeJson</v-icon>&nbsp;
                    <div class="share-button--text">{{ $i18n.t("badges.badge_url") }}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field class="badge-url submit-button" solo dense :value="badge.id" readonly>
                      <template v-slot:append>
                        <v-tooltip left color="#111" nudge-right="-5">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="copyUrl(badge.id)">
                              {{ copyIcon }}
                            </v-icon>
                          </template>
                          {{ copyText }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <div>
                      {{ $i18n.t("badges.badge_url_hint") }}
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels tile>
                <v-expansion-panel>
                  <v-expansion-panel-header color="#6c6c6c" class="copy-panel--header">
                    <v-icon>$vuetify.icons.imageMove</v-icon>&nbsp;
                    <div class="share-button--text">{{ $i18n.t("badges.baked_badge_url") }}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      class="badge-url submit-button"
                      solo
                      dense
                      :value="`${badgesAssetsAPI}/baked-badges/cmp/${id}.${badgeClass.image.substring(
                        badgeClass.image.lastIndexOf('.') + 1
                      )}`"
                      readonly
                    >
                      <template v-slot:append>
                        <v-tooltip left color="#111" nudge-right="-5">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              @click="
                                copyBakedBadgeUrl(
                                  `${badgesAssetsAPI}/baked-badges/cmp/${id}.${badgeClass.image.substring(
                                    badgeClass.image.lastIndexOf('.') + 1
                                  )}`
                                )
                              "
                            >
                              {{ copyBakedBadgeIcon }}
                            </v-icon>
                          </template>
                          {{ copyBakedBadgeText }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <div>
                      {{ $i18n.t("badges.baked_badge_url_hint") }}
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- SHARE BUTTONS -->
              <div class="share share-full-width">
                <a id="li" class="li li-full-width" target="_blank" :href="getLinkedInLink()"
                  ><v-icon>$vuetify.icons.linkedIn</v-icon>
                  <div class="li-text share-button--text">{{ $i18n.t("badges.linkedin_profile") }}</div></a
                >
              </div>
              <div class="share">
                <a id="fb" class="fb" target="_blank" :href="`${$i18n.t('badges.fb_share_url')}${landingPageUrl}/${id}`"
                  ><v-icon>$vuetify.icons.facebook </v-icon><span class="li-text"> Share</span></a
                >
                <a
                  class="tw"
                  :href="`${$i18n.t('badges.twitter_share_url')}${landingPageUrl}/${id}&text=${encodeURIComponent(
                    $i18n.t('badges.sharer_text.1') + ' ' + certShortName + ' ' + $i18n.t('badges.sharer_text.2')
                  )}`"
                  target="_blank"
                >
                  <v-icon>$vuetify.icons.twitter</v-icon><span class="li-text"> Tweet</span>
                </a>
                <a
                  id="li"
                  class="li"
                  target="_blank"
                  :href="`${$i18n.t('badges.linkedin_share_url')}${landingPageUrl}/${id}`"
                  ><v-icon>$vuetify.icons.linkedIn</v-icon><span class="li-text"> Share</span></a
                >
              </div>
              <!-- EMBED BADGE LINK -->
              <div class="share-embed-link">
                <a target="_blank" :href="`https://www.certible.com${$i18n.t('badges.embed_badge_url')}`">{{
                  $i18n.t("badges.embed_badge")
                }}</a>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- LOADING SPINNER -->
      <div class="verify-toggle" v-if="loading || !loadingFinished">
        <v-progress-circular :size="50" :width="7" color="#F3EC19" indeterminate></v-progress-circular>
      </div>
      <!-- BADGE NOT FOUND -->
      <div class="verify-toggle badge-text-title" v-if="!loading && loadingFinished && (!badge || !badge.id)">
        <div>{{ $i18n.t("badges.badge_not_found") }}</div>
      </div>
      <!-- MORE INFO LINK -->
      <v-divider class="badge-text--divider" />
      <div class="badge-text pb-9">
        <component :is="convertLink($i18n.t('claiming.moreinfo1'))"></component>
      </div>
      <!-- DELETE BADGE -->
      <div v-if="!loading && badge && badge.id && showOptions" class="badge-text badge-delete pb-9">
        <v-divider class="badge-delete--divider" />
        {{ $i18n.t("badges.delete.1") }}
        <span class="verify-show-toggle" @click="onShowToggle()"> {{ $i18n.t("badges.delete.2") }}</span>
        {{ $i18n.t("badges.delete.3") }}
        <span>{{ $i18n.t("badges.delete.4") }}</span>
      </div>
      <div class="verify-toggle pb-9" v-if="showToggle && !loading">
        <div class="verify-toggle--label">{{ $i18n.t("badges.delete.field") }}</div>
        <v-text-field v-model="password" solo></v-text-field>
        <v-btn tile color="#6c6c6c" :disabled="!password" class="verify-toggle--button" @click="onClick()">
          {{ $i18n.t("badges.delete.button") }}
        </v-btn>
      </div>
      <div class="verify-toggle pb-9" v-if="!loading && deletionFailure">
        <v-icon color="#800000" size="50"> $vuetify.icons.closeCircle </v-icon>
        <div>{{ $i18n.t("badges.delete.error") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "axios";
import { marked } from "marked";

export default {
  props: ["blok"],
  data() {
    return {
      personalData: null,
      password: "",
      loading: false,
      badge: null,
      badgeClass: null,
      claimed: null,
      id: null,
      showUrl: false,
      showBakedBadgeUrl: false,
      landingPageUrl: process.env.VUE_APP_BADGE_LANDING_URL,
      badgesAPI: process.env.VUE_APP_BADGES_API,
      badgesAssetsAPI: process.env.VUE_APP_BADGE_ASSETS_API,
      selfUrl: process.env.VUE_APP_SELF_URL,
      publicAPI: process.env.VUE_APP_PUBLIC_API_URL,
      copyBakedBadgeIcon: "$vuetify.icons.contentCopy",
      copyBakedBadgeText: "Copy",
      copyIcon: "$vuetify.icons.contentCopy",
      copyText: "Copy",
      loadingFinished: false,
      narrative: "",
      showToggle: false,
      deletionFailure: false,
      showOptions: false,
      schemes: null,
    };
  },
  async mounted() {
    this.loading = true;
    this.id = this.$route.params?.id;
    this.showOptions = this.$route.query?.showOptions ? true : false;
    await this.loadData();
    this.loading = false;
    this.loadingFinished = true;
  },
  computed: {
    hashTags() {
      const hashTags = this.badgeClass?.tags.map((tag) => "#" + tag.replace(/\s/g, ""));
      return hashTags?.join(" ") + " @CMPUpdates";
    },
    title() {
      if (this.personalData?.name && this.badgeClass?.name) {
        return this.personalData?.name + "'s Badge: " + this.badgeClass?.name;
      } else {
        return "Badge";
      }
    },
    description() {
      if (this.badgeClass?.description && this.hashTags) {
        return this.badgeClass?.description + " " + this.hashTags;
      } else {
        return "CMP Badge";
      }
    },
    certShortName() {
      if (!this.badgeClass || !this.badgeClass.id) {
        return null;
      }
      const certId = this.badgeClass.id.substring(this.badgeClass.id.lastIndexOf("/") + 1);
      if (this.schemes) {
        return this.schemes["CMP"].certs[certId]?.shortname;
      } else {
        return null;
      }
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          property: "og:title",
          content: this.title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.description,
          vmid: "og:description",
        },
        {
          property: "og:type",
          content: "website",
          vmid: "og:type",
        },
        {
          property: "og:image",
          content: this.badgeClass?.image
            ? this.badgeClass.image
            : "https://www.certible.com/assets/static/certible-logo.png",
          vmid: "og:image",
        },
        {
          property: "twitter:name",
          content: "CMP",
          vmid: "og:name",
        },
        {
          property: "twitter:title",
          content: this.title,
          vmid: "twitter:title",
        },
        {
          property: "twitter:description",
          content: this.description,
          vmid: "twitter:description",
        },
        {
          property: "twitter:image",
          content: this.badgeClass?.image
            ? this.badgeClass.image
            : "https://www.certible.com/assets/static/certible-logo.png",
          vmid: "twitter:image",
        },
      ],
    };
  },
  methods: {
    convertLink(text) {
      //check for links [text](url)
      let elements = text.match(/\[.*?\)/g);
      if (elements != null && elements.length > 0) {
        for (var el of elements) {
          let txt = el.match(/\[(.*?)\]/)[1]; //get only the txt
          let url = el.match(/\((.*?)\)/)[1]; //get only the link

          text = text.replace(
            el,
            '<a href="' +
              url +
              '" @click.stop target="_blank" rel="noopener noreferrer" class="text-link--lib">' +
              txt +
              "</a>"
          );
        }
      }
      text = "<span>" + text + "</span>";
      return {
        template: text,
      };
    },
    onShowToggle() {
      this.showToggle = !this.showToggle;
      this.deletionFailure = false;
    },
    async onClick() {
      this.loading = true;
      this.deletionFailure = false;
      try {
        let result = await post(`${this.badgesAPI}/cmp/delete/${this.id}`, {
          password: this.password,
        });
        if (!result.err) {
          console.log("ok", result);
        }
      } catch (error) {
        console.log(error);
        this.deletionFailure = true;
      }
      await this.loadData();
      this.showToggle = false;
      this.loading = false;
    },
    async loadData() {
      try {
        console.log("badge id", this.id);
        this.personalData = await get(`${this.badgesAPI}/cmp/personal-info/${this.id}`);
        this.personalData = this.personalData.data;
        this.badge = await get(`${this.badgesAPI}/cmp/badge/${this.id}`);
        this.badge = this.badge.data;
        if (this.badge.badge) {
          this.badgeClass = await get(this.badge.badge);
          this.badgeClass = this.badgeClass.data;
        }
        this.schemes = (await get(`${this.publicAPI}/schemes/cmp`)).data;
      } catch (error) {
        console.log(error);
      }
    },
    getNarrative() {
      if (this.badgeClass.criteria.narrative) {
        return marked(this.badgeClass.criteria.narrative);
      }
      return "";
    },
    copyUrl(text) {
      navigator.clipboard.writeText(text);
      this.copyIcon = "$vuetify.icons.checkboxMarkedCircle";
      this.copyText = "Copied";
      setTimeout(() => {
        this.showUrl = false;
        this.copyIcon = "$vuetify.icons.contentCopy";
        this.copyText = "Copy";
      }, 1500);
    },
    copyBakedBadgeUrl(text) {
      navigator.clipboard.writeText(text);
      this.copyBakedBadgeIcon = "$vuetify.icons.checkboxMarkedCircle";
      this.copyBakedBadgeText = "Copied";
      setTimeout(() => {
        this.showBakedBadgeUrl = false;
        this.copyBakedBadgeIcon = "$vuetify.icons.contentCopy";
        this.copyBakedBadgeText = "Copy";
      }, 1500);
    },
    getLinkedInLink() {
      let d = this.badge.issuedOn.split("-");
      let orgaId = "11082493";
      let issueYear = d[0];
      let issueMonth = +d[1];

      if (!this.badge.expires) {
        return (
          `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&` +
          `name=${encodeURIComponent(this.badgeClass.name)}&` +
          `organizationId=${orgaId}&` +
          `issueYear=${issueYear}&` +
          `issueMonth=${issueMonth}&` +
          `certUrl=${this.landingPageUrl}/${this.id}&` +
          `certId=${this.badge.id}`
        );
      }

      d = this.badge.expires.split("-");
      let expireYear = d[0];
      let expireMonth = +d[1];

      return (
        `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&` +
        `name=${encodeURIComponent(this.badgeClass.name)}&` +
        `organizationId=${orgaId}&` +
        `issueYear=${issueYear}&` +
        `issueMonth=${issueMonth}&` +
        `expirationYear=${expireYear}&` +
        `expirationMonth=${expireMonth}&` +
        `certUrl=${this.landingPageUrl}/${this.id}&` +
        `certId=${this.badge.id}`
      );
    },
  },
};
</script>

<style scoped>
.badges-container {
  padding: 0 5rem;
  max-width: 1200px;
  margin: 0 auto;
}
.share {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.share-post {
  margin-top: 0;
  font-size: 0.9rem;
  text-align: left;
}

.share a {
  text-align: center;
  display: inline-block;
  width: 120px;
  padding: 4px 0 !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: bold;
  margin-right: 1rem;
  text-decoration: none;
}

.li-text {
  font-weight: 600;
  font-size: 14px;
}

.share-full-width a {
  width: 100% !important;
  padding: 0 1rem !important;
  height: 36px !important;
}

.share a:last-of-type {
  margin-right: 0;
}

.fb {
  background: #359 !important;
}
.v-icon {
  color: white !important;
}
.tw {
  background: #5ae !important;
}

.verify-toggle .v-icon.v-icon {
  color: #800000 !important;
}

.share a:hover {
  background: #222 !important;
}

.li {
  background: #07a !important;
}

.badge-text-criteria {
  margin-bottom: 1rem;
}

.badge-text--line-break {
  white-space: pre-line;
}

.share-embed-link {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-style: italic;
}

.share-embed-link :hover {
  text-decoration: underline;
}
.verify-toggle {
  text-align: center;
  margin-top: 2rem;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.verify-toggle--button {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}
.verify-toggle--label {
  margin-bottom: 1rem;
  text-align: left;
}
.badge-title {
  text-align: left;
  font-size: 3.5rem;
  font-weight: 700;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
  line-height: 4rem;
}
.badge-subtitle {
  color: rgb(108, 108, 108);
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.5rem;
  text-align: left;
}
.badge-issued-text {
  font-style: italic;
  color: rgb(108, 108, 108);
  text-align: left;
}
.badge-image-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.badge-image {
  max-width: 100%;
}
.v-expansion-panels {
  margin-top: 1rem !important;
}
.v-expansion-panel-header {
  min-height: 36px !important;
  height: 36px !important;
  padding: 0 1rem !important;
  padding: 0 1rem !important;
  justify-content: center;
  text-align: center !important;
}
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0rem 0.5rem 0.5rem 0.5rem !important;
}
.v-expansion-panel-content::v-deep .v-icon {
  color: black !important;
}
.v-expansion-panel-content .submit-button {
  margin-top: 0.5rem !important;
  margin-bottom: -18px !important;
}
.submit-button-container {
  text-decoration: none;
}
.submit-button {
  margin-top: 1rem !important;
  width: 100%;
}
.badge-text {
  margin-top: 1rem;
}
.badge-chips {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.badge-chips-container {
  margin-top: 1.5rem;
}
.badge-text-title {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.badge-target-name {
  margin-bottom: 1rem;
}
.badge-delete {
  margin-top: 3rem;
}
.badge-delete--divider {
  margin-bottom: 1rem;
}
.badge-text--divider {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.badge-url {
  border-radius: 0 !important;
  margin-bottom: -28px !important;
}
.badge--share-container {
  max-width: 280px;
  min-width: 300px;
}
@media (max-width: 859px) {
  .badge-title {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 3rem;
  }
  .badge-subtitle {
    font-size: 1.2rem !important;
    line-height: 1.3rem !important;
  }
  .badges-container {
    padding: 0 1rem;
    max-width: auto;
    margin: 0 auto;
  }
}
.share-button--text {
  width: -webkit-fill-available;
  width: -moz-available;
  color: white;
  font-weight: 500;
  text-transform: none;
}
.li-full-width {
  display: flex !important;
  align-items: center;
}
.verify-show-toggle {
  border-bottom: 1px solid #1976d2;
  color: #1976d2;
  cursor: pointer;
}
</style>
