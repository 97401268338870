import axios from "axios";
import { store } from "../main";

export async function getSignedUrl(tenant, fileName, random) {
  var documentInfo = {
    tenant: tenant,
    key: fileName,
    random: random,
  };

  console.log(documentInfo);

  for (var i = 1; i <= 5; i++) {
    try {
      const res = await fetch(process.env.VUE_APP_DOCUMENT_UPLOAD_URL + "/document-signed-url", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(documentInfo),
      });
      if (!res.ok) {
        if (i === 5) {
          return null;
        }
      } else {
        return res.json();
      }
    } catch (err) {
      console.log(err);
      if (i === 5) {
        return null;
      }
    }
  }
}

export async function uploadDocument(file, url, id, index) {
  for (var i = 1; i <= 5; i++) {
    try {
      const res = await axios.put(url, file, {
        onUploadProgress: function(progressEvent) {
          var uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          console.log(uploadPercentage);
          store.dispatch("registration/updateUploadStatus", { id: id, index: index, status: uploadPercentage });
        },
      });
      console.log(res);
      if (!res.status === 200) {
        if (i === 5) {
          return null;
        }
      } else {
        return res;
      }
    } catch (err) {
      console.log(err);
      if (i === 5) {
        return null;
      }
    }
  }
}

export async function uploadDocuments(documents, tenant, random) {
  var uploadedDocuments = Object.create(null);
  var failedDocuments = Object.create(null);

  for (var docId in documents) {
    var index = 1;
    for (var doc of documents[docId]) {
      if (doc.file != null) {
        await getSignedUrl(tenant, docId + "_" + index, random).then(async (res) => {
          if (res.url) {
            console.log("got signed url");
            await uploadDocument(doc.file, res.url, docId, index - 1).then((resUpload) => {
              if (resUpload) {
                console.log("file uploaded successfully");
                if (!uploadedDocuments[docId]) {
                  uploadedDocuments[docId] = [];
                }
                uploadedDocuments[docId].push({
                  userFilename: doc.file.name,
                  storageIndex: index,
                });
              } else {
                console.log("file upload failed");
                if (!failedDocuments[docId]) {
                  failedDocuments[docId] = [];
                }
                failedDocuments[docId].push({
                  userFilename: doc.file.name,
                  storageIndex: index,
                });
              }
            });
          } else {
            console.log("signed url failed");
            if (!failedDocuments[docId]) {
              failedDocuments[docId] = [];
            }
            failedDocuments[docId].push({
              userFilename: doc.file.name,
              storageIndex: index,
            });
          }
        });
        index++;
      }
    }
  }
  return { uploaded: uploadedDocuments, failed: failedDocuments };
}
