<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <div class="mx-3 my-3">
      <!-- Gender -->
      <v-row no-gutters align="center" class="">
        <v-col cols="12">
          <div class="text-label--title-big">{{ $t("registration.steps.2") }}</div>
          <v-radio-group
            v-model="personalInfo.gender"
            row
            :class="{ 'input-error-radio': $v.personalInfo.gender.$error }"
          >
            <v-radio
              v-for="gender in $t('registration.personal_info.gender')"
              :key="gender.value"
              :label="gender.text"
              :color="uiColor"
              :value="gender.value"
              class="black--text radio-button--margin"
              :class="{
                'black--text': !$v.personalInfo.gender.$error,
                'input-error--text': $v.personalInfo.gender.$error,
              }"
            ></v-radio>
            <InfoTooltip :text="$t('registration.personal_info.gender_info')" tabindex="5" />
          </v-radio-group>
          <div class="text-error" v-if="$v.personalInfo.gender.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Vorname, Nachname -->
      <v-row no-gutters align="center">
        <v-col cols="12" md="6" align="left" class="textfield-margin">
          <div class="pb-2">
            <span class="text-label">{{ $t("registration.personal_info.first_name.text") }}</span>
            <span class="text-optional">{{ $t("registration.required") }}</span>
            <InfoTooltip :text="$t('registration.personal_info.first_name.info')" />
          </div>
          <v-text-field
            v-model="personalInfo.firstname"
            solo
            :class="{ 'input-error': $v.personalInfo.firstname.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.personalInfo.firstname.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
        <v-col cols="12" md="6" align="left" class="pl-md-2 textfield-margin">
          <div class="pb-2">
            <span class="text-label">{{ $t("registration.personal_info.last_name.text") }}</span>
            <span class="text-optional">{{ $t("registration.required") }}</span>
            <InfoTooltip :text="$t('registration.personal_info.last_name.info')" />
          </div>
          <v-text-field
            v-model="personalInfo.lastname"
            solo
            :class="{ 'input-error': $v.personalInfo.lastname.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.personalInfo.lastname.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Adresse -->
      <v-row no-gutters align="center">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.personal_info.address.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.address.info')" />
        </v-col>
      </v-row>
      <!-- Country Chooser -->
      <v-row no-gutters align="center" class="">
        <v-col cols="12">
          <v-radio-group
            v-model="personalInfo.address.countryChooser"
            row
            :class="{ 'input-error-radio': $v.personalInfo.address.countryChooser.$error }"
          >
            <v-radio
              v-for="val in $t('registration.personal_info.country_chooser')"
              :key="val"
              :label="val"
              :color="uiColor"
              :value="val"
              class="black--text radio-button--margin"
              :class="{
                'black--text': !$v.personalInfo.address.countryChooser.$error,
                'input-error--text': $v.personalInfo.address.countryChooser.$error,
              }"
            ></v-radio>
          </v-radio-group>
          <div class="text-error" v-if="$v.personalInfo.address.countryChooser.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field
            v-model="personalInfo.address.line1"
            solo
            :class="{ 'input-error': $v.personalInfo.address.line1.$error }"
          ></v-text-field>
          <div
            class="text-error"
            v-if="$v.personalInfo.address.line1.$error && !$v.personalInfo.address.line1.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field v-model="personalInfo.address.line2" solo></v-text-field>
        </v-col>
      </v-row>
      <!-- Stadt, PLZ -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="6" align="left" class="pr-2">
          <span class="text-label">{{ $t("registration.personal_info.zip_code.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.zip_code.info')" />
        </v-col>
        <v-col cols="6" align="left" class="pl-2">
          <span class="text-label">{{ $t("registration.personal_info.city.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.city.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="6" align="left" class="pr-2">
          <v-text-field
            v-model="personalInfo.address.zip"
            solo
            id="zip"
            :class="{ 'input-error': $v.personalInfo.address.zip.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.personalInfo.address.zip.$error && !$v.personalInfo.address.zip.required">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
        <v-col cols="6" align="left" class="pl-2">
          <v-text-field
            v-model="personalInfo.address.city"
            solo
            :class="{ 'input-error': $v.personalInfo.address.city.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.personalInfo.address.city.$error && !$v.personalInfo.address.city.required">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- State -->
      <v-row no-gutters align="center" v-if="personalInfo.address.countryChooser === 'US'" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.personal_info.state.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.state.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" v-if="personalInfo.address.countryChooser === 'US'" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-select
            :items="$t('registration.personal_info.state.options')"
            attach
            solo
            :label="$t('registration.personal_info.state.text')"
            class="select"
            :color="uiColor"
            v-model="personalInfo.address.state"
            :item-color="uiColor"
            :class="{
              'input-error': $v.personalInfo.address.state.$error,
              focused: focused1,
            }"
          ></v-select>
          <div
            class="text-error"
            v-if="$v.personalInfo.address.state.$error && !$v.personalInfo.address.state.required"
          >
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Country -->
      <v-row no-gutters align="center" v-if="personalInfo.address.countryChooser !== 'US'">
        <v-col cols="12" align="left" class="textfield-margin">
          <div class="pb-2">
            <span class="text-label">{{ $t("registration.personal_info.country.text") }}</span>
            <span class="text-optional">{{ $t("registration.required") }}</span>
            <InfoTooltip :text="$t('registration.personal_info.country.info')" />
          </div>
          <v-select
            :items="getAvalilableCountries"
            item-text="name"
            item-value="code"
            attach
            solo
            id="country"
            :color="uiColor"
            tabindex="0"
            name="country"
            v-model="personalInfo.address.country"
            item-:color="uiColor"
            :class="{
              'input-error': $v.personalInfo.address.country.$error,
            }"
          ></v-select>
          <div class="text-error" v-if="$v.personalInfo.address.country.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- E-Mail -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.personal_info.e-mail.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.e-mail.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-col cols="12" align="left">
          <v-text-field
            v-model="personalInfo.email"
            solo
            :class="{ 'input-error': $v.personalInfo.email.$error }"
          ></v-text-field>
          <div class="text-error" v-if="$v.personalInfo.email.$error && !$v.personalInfo.email.required">
            {{ $t("registration.required_field") }}
          </div>
          <div class="text-error" v-if="$v.personalInfo.email.$error && !$v.personalInfo.email.email">
            {{ $t("registration.e-mail_invalid") }}
          </div>
        </v-col>
      </v-row>
      <!-- Telefon -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.personal_info.phone.text") }}</span>
          <span class="text-optional">{{ $t("registration.optional") }}</span>
          <InfoTooltip :text="$t('registration.personal_info.phone.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin mb-md-3">
        <v-col cols="12" align="left">
          <v-text-field v-model="personalInfo.phone" solo type="text"></v-text-field>
        </v-col>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div>
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { required, email, requiredIf } from "vuelidate/lib/validators";
import InfoTooltip from "./InfoTooltip";

export default {
  name: "PersonalInfo",
  components: {
    InfoTooltip,
  },
  data() {
    return {
      countryFocused: false,
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
      menu: false,
      focused1: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  validations: {
    personalInfo: {
      gender: {
        required,
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email,
      },
      address: {
        countryChooser: {
          required,
        },
        line1: {
          required,
        },
        city: {
          required,
        },
        zip: {
          required,
        },
        state: {
          required: requiredIf(function () {
            return this.personalInfo.address.countryChooser === "US";
          }),
        },
        country: {
          required: requiredIf(function () {
            return this.personalInfo.address.countryChooser !== "US";
          }),
        },
      },
      birthday: {
        required: requiredIf(function () {
          if (this.tenant === "as") {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    personalInfo: {
      get() {
        return this.$store.getters["registration/personalInfo"];
      },
      set(value) {
        this.$store.dispatch("registration/updatePersonalInfo", value);
      },
    },
    getAvalilableCountries() {
      return this.$store.getters["registration/availableCountriesForBilling"];
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
</style>
