<template>
  <v-container class="main-container d-flex flex-column justify-center">
    <div class="mx-3 mt-3 mb-10">
      <!-- Business model -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <div class="text-label--title-big mb-4">{{ $t("registration.steps.7") }}</div>
          <div class="text-label">
            {{ $t("registration.demographics.info") }}
          </div>
          <v-divider class="mt-3 mb-3"></v-divider>
          <span class="text-label">{{ $t("registration.demographics.business_model.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.business_model.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="demographics.businessModel" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.demographics.business_model.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.demographics.businessModel.$error,
              'input-error--text': $v.demographics.businessModel.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.demographics.businessModel.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Annual Reviews -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.demographics.annual_reviews.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.annual_reviews.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="demographics.annualReviews" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.demographics.annual_reviews.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.demographics.annualReviews.$error,
              'input-error--text': $v.demographics.annualReviews.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.demographics.annualReviews.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Customer satisfaction employer -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.demographics.customer_satisfaction.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.customer_satisfaction.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="demographics.customerSatisfaction" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.demographics.customer_satisfaction.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.demographics.customerSatisfaction.$error,
              'input-error--text': $v.demographics.customerSatisfaction.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.demographics.customerSatisfaction.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Stay at current employer -->
      <v-row no-gutters align="center" class="mb-2">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.demographics.stay_at_current_employer.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.stay_at_current_employer.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" class="textfield-margin">
        <v-radio-group v-model="demographics.stayAtCurrentEmployer" class="radio-question--margin">
          <v-radio
            v-for="answer in $t('registration.demographics.stay_at_current_employer.answers')"
            :key="answer"
            :label="answer"
            :color="uiColor"
            :value="answer"
            class="black--text radio-button--margin"
            :class="{
              'black--text': !$v.demographics.stayAtCurrentEmployer.$error,
              'input-error--text': $v.demographics.stayAtCurrentEmployer.$error,
            }"
          ></v-radio>
        </v-radio-group>
        <div class="text-error" v-if="$v.demographics.stayAtCurrentEmployer.$error">
          {{ $t("registration.required_field") }}
        </div>
      </v-row>
      <!-- Channels - Agent -->
      <v-row no-gutters align="center" class="mb-2" v-if="certId === 'CMPCA'">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.demographics.channels_agent.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.channels_agent.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" v-if="certId === 'CMPCA'">
        <v-col cols="12" align="left">
          <v-select
            :items="$t('registration.demographics.channels_agent.answers')"
            multiple
            attach
            solo
            label="Channels"
            class="select"
            :color="uiColor"
            v-model="demographics.channelsAgent"
            :item-color="uiColor"
            :class="{
              'input-error': $v.demographics.channelsAgent.$error,
              focused: focused1,
            }"
            @focus="focused1 = true"
            @blur="focused1 = false"
            :menu-props="{ top: true }"
          ></v-select>
          <div class="text-error" v-if="$v.demographics.channelsAgent.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
      <!-- Channels - Leader -->
      <v-row no-gutters align="center" class="mb-2" v-if="certId === 'CMPCL'">
        <v-col cols="12" align="left">
          <span class="text-label">{{ $t("registration.demographics.channels_leader.text") }}</span>
          <span class="text-optional">{{ $t("registration.required") }}</span>
          <InfoTooltip :text="$t('registration.demographics.channels_leader.info')" />
        </v-col>
      </v-row>
      <v-row no-gutters align="center" v-if="certId === 'CMPCL'">
        <v-col cols="12" align="left">
          <v-select
            :items="$t('registration.demographics.channels_leader.answers')"
            multiple
            attach
            solo
            label="Channels"
            class="select"
            :color="uiColor"
            v-model="demographics.channelsLeader"
            :item-color="uiColor"
            :class="{
              'input-error': $v.demographics.channelsLeader.$error,
              focused: focused2,
            }"
            @focus="focused2 = true"
            @blur="focused2 = false"
            :menu-props="{ top: true }"
          ></v-select>
          <div class="text-error" v-if="$v.demographics.channelsLeader.$error">
            {{ $t("registration.required_field") }}
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Navigation Buttons -->
    <div>
      <v-btn tile :color="uiColor" class="next-button" v-on:click="validateForm">{{
        $t("registration.proceed")
      }}</v-btn>
      <v-btn tile :color="uiColor" class="back-button" v-on:click="$emit('back-button-clicked')">{{
        $t("registration.back")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import InfoTooltip from "./InfoTooltip";

export default {
  name: "Demographics",
  components: {
    InfoTooltip,
  },
  validations: {
    demographics: {
      annualReviews: {
        required,
      },
      customerSatisfaction: {
        required,
      },
      stayAtCurrentEmployer: {
        required,
      },
      businessModel: {
        required,
      },
      channelsAgent: {
        required: requiredIf(function () {
          return this.certId === "CMPCA";
        }),
        minLength: minLength(1),
      },
      channelsLeader: {
        required: requiredIf(function () {
          return this.certId === "CMPCL";
        }),
        minLength: minLength(1),
      },
    },
  },
  data() {
    return {
      tenant: this.$store.getters["registration/tenant"],
      uiColor: this.$store.getters["registration/uiColor"],
      focused1: false,
      focused2: false,
    };
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("next-button-clicked");
      }
    },
  },
  computed: {
    demographics: {
      get() {
        return this.$store.getters["registration/demographics"];
      },
      set(value) {
        this.$store.dispatch("registration/updateDemographics", value);
      },
    },
    certId() {
      return this.$store.getters["registration/certificationType"].certId;
    },
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
}
.textfield-margin {
  margin-bottom: -14px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 0;
  padding: 0;
  margin: 0;
}
.black--text ::v-deep label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 8px;
}
.input-error ::v-deep .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(240, 64, 79, 0.2), 0px 2px 2px 0px rgba(240, 64, 79, 0.2),
    0px 1px 5px 0px rgba(240, 64, 79, 0.2) !important;
}
.input-error-radio ::v-deep i {
  color: rgba(240, 64, 79, 0.8);
}
.focused ::v-deep .v-input__slot {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.input-error--text ::v-deep label {
  font-size: 18px;
  color: #f03434;
}
.select-cursor ::v-deep input {
  cursor: pointer !important;
}
.select-cursor ::v-deep .v-input__slot {
  cursor: pointer !important;
}
.radio-question--margin {
  margin-top: 0px !important;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.radio-block--margin {
  margin-bottom: -30px;
}
.radio-block--margin:last-of-type {
  margin-bottom: 0rem !important;
}
</style>
