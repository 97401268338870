<template>
  <v-container class="px-0 pb-0 pt-5">
    <!-- Checkout texts -->
    <v-row class="align-content-end">
      <v-col cols="12" md="10" offset="0" offset-md="1" class="pt-0">
        <div class="text-label--title-big mb-4 mt-9">{{ $t("registration.steps.8") }}</div>
        <div class="text-left">
          <div class="text-checkout-details">{{ certificationType.certificationPath }}</div>
          <v-divider class="mt-5 mb-5"></v-divider>
          <div class="text-checkout-sum">{{ $t("registration.summary_personal_info") }}</div>
          <div class="text-checkout-details">{{ personalInfo.firstname }} {{ personalInfo.lastname }}</div>
          <div class="text-checkout-details">
            {{ personalInfo.email }}
          </div>
          <div class="text-checkout-details" v-if="personalInfo.phone">
            {{ personalInfo.phone }}
          </div>
        </div>
        <v-divider class="mt-5 mb-5"></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegInfoSummary",
  computed: {
    personalInfo() {
      return this.$store.getters["registration/personalInfo"];
    },
    certificationType() {
      return this.$store.getters["registration/certificationType"];
    },
  },
};
</script>
