<template>
  <div>
    <!-- CMP Header -->
    <div id="header_main">
      <div id="header_main_inner" class="container_cmp">
        <div id="header_main_alpha" role="banner">
          <div id="header_logo">
            <a :href="$t('registration.header_url')">
              <img id="hlogo_normal" src="@/assets/cmp_logo.png" alt="Logo - Customer Management Practice" />
              <img id="hlogo_icon" src="@/assets/cmp_logo.png" alt="Logo Icon - Customer Management Practice" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="badges-container">
      <div class="verify-toggle" v-if="loading">
        <v-progress-circular :size="50" :width="7" color="#F3EC19" indeterminate></v-progress-circular>
        <div v-if="claimingLoading" class="loading--text">{{ $i18n.t("claiming.waiting") }}</div>
      </div>

      <!-- BADGE NOT FOUND -->
      <div
        class="verify-toggle badge-text-title"
        v-if="!loading && loadingFinished && (!badgeRequest || !badgeRequest.status)"
      >
        <div>{{ $i18n.t("badges.badge_not_found") }}</div>
      </div>

      <!-- BADGE ALREADY CLAIMED -->
      <div
        v-if="!loading && loadingFinished && badgeRequest && badgeRequest.status && badgeRequest.status === 'claimed'"
      >
        <h3>{{ $i18n.t("claiming.claimed1") }}</h3>
        <a id="badgeLanding" :href="`${landingPageUrl}/${id}`">
          <v-btn class="text-none button--large" color="#7CB4B8" x-large>
            {{ $i18n.t("claiming.claimed2") }}
          </v-btn>
        </a>
      </div>

      <!-- CLAIM BADGE -->
      <div v-if="!loading && loadingFinished && badgeRequest && badgeRequest.status === 'unclaimed'">
        <div class="badge-class--image" v-if="badgeClass && badgeClass.image">
          <img :src="badgeClass.image" />
          <div class="badge-class--name">{{ badgeClass.name }}</div>
        </div>

        <div class="claiming--element claiming--header">{{ $i18n.t("claiming.instructions1") }}</div>
        <!-- CLAIMING ERROR -->
        <div v-if="!loading && claimFailure" class="claiming--error">
          <v-icon class="claiming--error-icon" color="#800000" size="50"> $vuetify.icons.closeCircle </v-icon>
          <div>{{ $i18n.t("claiming.error") }}</div>
        </div>
        <div class="claiming--element">
          <v-text-field
            v-model="password"
            :label="$i18n.t('claiming.pw')"
            type="password"
            solo
            class="claiming--password"
            autocomplete="one-time-code"
          ></v-text-field>
          <div v-for="consent in $i18n.t(consentDeclarations)" :key="consent.id">
            <v-checkbox
              v-model="consentAccepted[consent.id]"
              class="claiming--checkbox"
              color="#7CB4B8"
              :class="{
                'input-error': invalidConsentFields.includes(consent.id),
              }"
            >
              <span
                slot="label"
                class="text-label"
                :class="{
                  'input-error--text': invalidConsentFields.includes(consent.id),
                }"
              >
                <component :is="convertLink(consent.text)"></component>
              </span>
            </v-checkbox>
            <div v-if="invalidConsentFields.includes(consent.id)" class="claiming--required-field">
              {{ $i18n.t("claiming.required_field") }}
            </div>
          </div>
          <v-btn class="text-none button--large" :disabled="!password" color="#7CB4B8" @click="onClick()" x-large>
            {{ $i18n.t("claiming.submit") }}
          </v-btn>

          <!-- QUESTION 1-->
          <v-divider class="claiming--divider" />
          <div class="claiming--element claiming--question">{{ $i18n.t("claiming.question1") }}</div>
          <div class="claiming--answer">{{ $i18n.t("claiming.answer1") }}</div>
          <!-- QUESTION 2-->
          <div class="claiming--element claiming--question">{{ $i18n.t("claiming.question2") }}</div>
          <div class="claiming--answer">{{ $i18n.t("claiming.answer2") }}</div>
          <!-- QUESTION 3-->
          <div class="claiming--element claiming--question">{{ $i18n.t("claiming.question3") }}</div>
          <div class="claiming--answer"><component :is="convertLink($i18n.t('claiming.answer3'))"></component></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "axios";

export default {
  props: ["blok"],
  data() {
    return {
      verifiedData: null,
      password: "",
      id: null,
      loading: false,
      claimed: null,
      claimFailure: false,
      invalidConsentFields: [],
      badgeRequest: null,
      badgeClass: null,
      badgesAPI: process.env.VUE_APP_BADGES_API,
      landingPageUrl: process.env.VUE_APP_BADGE_LANDING_URL,
      consentDeclarations: "claiming.consentDeclarations", // i18n path
      consentAccepted: {},
      badgesAssetsAPI: process.env.VUE_APP_BADGE_ASSETS_API,
      claimingLoading: false,
      loadingFinished: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.id = this.$route.params?.id;
    await this.loadData();
    this.loading = false;
    this.loadingFinished = true;
  },
  methods: {
    async loadData() {
      try {
        this.badgeRequest = await get(`${this.badgesAPI}/cmp/request/${this.id}`);
        this.badgeRequest = this.badgeRequest.data;
        this.badgeClass = await get(`${this.badgesAPI}/cmp/badge-class/${this.badgeRequest.class}`);
        this.badgeClass = this.badgeClass.data;
      } catch (error) {
        console.log(error);
      }
    },

    async onClick() {
      if (!this.validateConsentFields()) {
        console.log("fields invalid");
        return;
      }
      this.loading = true;
      this.claimingLoading = true;
      this.claimFailure = false;
      try {
        let result = await post(`${this.badgesAPI}/cmp/claim`, {
          id: this.id,
          password: this.password,
          consentDeclarations: this.$i18n.t(this.consentDeclarations),
          consentAccepted: this.consentAccepted,
        });
        if (!result.err) {
          console.log("ok", result);
          await this.fetchBakedBadge();
          window.location.href = `${this.landingPageUrl}/${this.id}`;
        }
      } catch (error) {
        console.log(error);
        this.claimFailure = true;
      }
      this.loading = false;
      this.claimingLoading = false;
    },

    async fetchBakedBadge() {
      try {
        let retries = 10;
        while (retries > 0) {
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          try {
            console.log(
              "request",
              `${this.badgesAssetsAPI}/baked-badges/cmp/${this.id}.${this.badgeClass.image.substring(
                this.badgeClass.image.lastIndexOf(".") + 1
              )}`
            );
            const req = await get(
              `${this.badgesAssetsAPI}/baked-badges/cmp/${this.id}.${this.badgeClass.image.substring(
                this.badgeClass.image.lastIndexOf(".") + 1
              )}`
            );
            if (req) {
              retries--;
              console.log("badge successfully claimed");
              break;
            } else {
              retries--;
              console.log("cannot fetch data");
            }
          } catch (error) {
            retries--;
            console.log("cannot fetch data");
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    validateConsentFields() {
      console.log("got here");
      this.invalidConsentFields = [];
      for (var consent of this.$i18n.t(this.consentDeclarations)) {
        if (consent.mandatory === true) {
          if (!(this.consentAccepted[consent.id] && this.consentAccepted[consent.id] === true)) {
            this.invalidConsentFields.push(consent.id);
          }
        }
      }
      if (this.invalidConsentFields.length > 0) {
        return false;
      }
      return true;
    },

    convertLink(text) {
      //check for links [text](url)
      let elements = text.match(/\[.*?\)/g);
      if (elements != null && elements.length > 0) {
        for (let el of elements) {
          let txt = el.match(/\[(.*?)\]/)[1]; //get only the txt
          let url = el.match(/\((.*?)\)/)[1]; //get only the link

          text = text.replace(
            el,
            '<a href="' +
              url +
              '" @click.stop target="_blank" rel="noopener noreferrer" class="text-link--lib">' +
              txt +
              "</a>"
          );
        }
      }
      text = "<span>" + text + "</span>";
      return {
        template: text,
      };
    },
  },
};
</script>

<style scoped>
.badges-container {
  padding: 0 5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.claiming--required-field {
  font-size: 0.8rem;
  color: #f03434;
  margin-top: -1rem;
  font-style: italic;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.claiming--action-container {
  margin-left: auto;
  margin-right: auto;
}

.claiming--element {
  align-items: left;
  margin: 1rem 0;
}

.claiming--checkbox {
  margin-top: -15px !important;
  color: #f3ec19 !important;
}

.claiming--password {
  width: 40%;
  margin-bottom: 2rem !important;
  margin-top: 3rem !important;
}

.claiming--error-icon {
  color: #800000 !important;
  margin-right: 1rem;
  float: left;
  margin-top: -12px;
}

.claiming--header {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.claiming--divider {
  margin-top: 5rem;
  margin-bottom: 1.5rem !important;
  max-width: 700px !important;
}

.badge-class--image {
  float: right;
  margin-left: 2rem;
  max-width: 300px;
  min-height: 500px;
}

.badge-class--image img {
  max-width: 300px;
}

.badge-class--more-info {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 1000px) {
  .badge-class--image {
    float: none;
    text-align: center;
    margin: 2rem auto;
    min-height: unset !important;
  }

  .claiming--checkbox {
    margin-top: -15px !important;
  }

  .claiming--password {
    width: auto;
  }
}
.verify-toggle {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.loading--text {
  margin-top: 1rem;
}
.badge-class--name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}
.claiming--question {
  font-size: 1.4rem;
  font-weight: 600;
}
.claiming--answer {
  margin-bottom: 2rem;
}
.claiming--error {
  margin: 3rem 0;
}
#badgeLanding {
  text-decoration: none !important;
}
.button--large {
  font-size: 1.1rem !important;
}
</style>
