<template>
  <v-tooltip right tabindex="-1" v-if="text">
    <template v-slot:activator="{ on, attrs }" tabindex="-1">
      <v-btn icon v-bind="attrs" v-on="on" tabindex="-1">
        <v-icon color="grey" class="info-icon" tabindex="-1"> $vuetify.icons.information </v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "InfoTooltip",
  props: ["text"],
};
</script>
