export async function getUserSecret(regInfo) {
  if (regInfo.hash) {
    delete regInfo.hash;
  }

  const msgBuffer = new TextEncoder().encode(JSON.stringify(regInfo));
  var hash = typeof window !== "undefined" ? await window.crypto.subtle.digest("SHA-256", msgBuffer) : null;
  var hashString = btoa(String.fromCharCode.apply(null, new Uint8Array(hash)));

  regInfo["hash"] = hashString;

  for (var i = 1; i <= 5; i++) {
    try {
      const res = await fetch(process.env.VUE_APP_BACKEND_URL + "/application", {
        //const res = await fetch(process.env.VUE_APP_BACKEND_URL + "/create-payment-intent", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(regInfo),
      });
      if (!res.ok) {
        if (i === 5) {
          console.log(res.status);
          return { error: true, status: res.status };
        }
      } else {
        return res.json();
      }
    } catch (err) {
      console.log(err);
      if (i === 5) {
        return null;
      }
    }
  }
}
